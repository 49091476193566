import React, {Component} from 'react';
import {parseURL} from '../../helper/helper';
import NotFoundPage from '../notFoundPage/notFoundPage';
import QrBody from '../QrBody/QrBody';
import IframeQrBody from '../IframeQrBody/IframeQrBody';

class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      url: this.props.url ? this.props.url : window.location.href,
      requestParamQr: this.props.requestParamQr,
      requestParamBarCode: this.props.requestParamBarCode,
      isInvalidUrl: false
    };

    this.parseURL = parseURL.bind(this);
  }

  componentDidMount() {
    const {url} = this.state;

    // Replace any HTML-encoded entities like &amp; with the correct characters
    const cleanedUrl = url.replace(/&amp;/g, '&');

    // Check if URL contains valid query parameters
    if (!cleanedUrl.includes('?')) {
      this.setState({isInvalidUrl: true});
    } else {
      // Parse the cleaned URL
      const urlObj = this.parseURL(cleanedUrl);
      const urlParams = this.decodeParams(urlObj.params);
      this.setState({params: urlParams});
    }
  }

  // Helper method to decode the parameters
  decodeParams(params) {
    const decodedParams = {};
    Object.keys(params).forEach((key) => {
      decodedParams[key] = decodeURIComponent(params[key]);
    });
    return decodedParams;
  }

  search(term) {
    let results = {};
    const {params} = this.state;
    Object.keys(params).forEach((key) => {
      if (key.toLowerCase().includes(term.toLowerCase()) || params[key].toLowerCase().includes(term.toLowerCase())) {
        results = {...results, [key]: params[key]};
      }
    });
    return {results};
  }

  render() {
    const {
      isInvalidUrl, params, requestParamQr, requestParamBarCode
    } = this.state;

    if (isInvalidUrl) {
      return <NotFoundPage />;
    }

    if (this.props.isIframeComponent) {
      return (
        <IframeQrBody
          themeColor={params.backgroundColor}
          PaymentSystemId={params.ps}
          qrImage={params.QRCODE}
          qrCode={params.QR}
          barCode={params.BarCode}
          requestParamQr={requestParamQr}
          requestParamBarCode={requestParamBarCode}
        />
      );
    }

    return (
      <>
        {params.QR ? (
          <QrBody
            TransactionID={params.clientRequestId}
            UserID={params.customerId}
            Amount={params.transactionAmount}
            Message={params.mes}
            Currency={params.currency}
            ServiceProviderId={params.sp}
            PaymentSystemId={params.ps}
            PaymentSystemName={params.psName}
            qrImage={params.QRCODE}
            qrCode={params.QR}
            barCode={params.BarCode}
            isNew={params.isNew}
            CryptoCurrency={params.cryptoCurrency}
            CryptoAmount={params.cryptoAmount}
            CopiedText={this.search('Copy')}
            backUrl={params.backUrl}
            requestParamQr={requestParamQr}
            requestParamBarCode={requestParamBarCode}
            Language={params.lang}
            generateQrCode={params.generateQrCode}
            createQR={params.createQR}
            isIframeComponent={this.props.isIframeComponent}
            CopyWalletAddress={params.CopyWalletAddress}
            CopyDestinationTag={params.CopyDestinationTag}
          />
        ) : null}
      </>
    );
  }
}

export default QRPage;
